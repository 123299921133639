import { forwardRef } from "react";
import { cn } from "../utils";
import * as LabelPrimitive from "@radix-ui/react-label";

interface LabelProps extends LabelPrimitive.LabelProps {
  help?: string;
}

export const Label = forwardRef<HTMLLabelElement, LabelProps>(
  ({ className, help, children, ...props }: LabelProps, ref) => {
    return (
      <LabelPrimitive.Root
        ref={ref}
        className={cn("text-content flex items-center space-x-2 text-xs leading-none", className)}
        {...props}
      >
        <span>{children}</span>
        {help && <span className="text-content/60 ml-2">{help}</span>}
      </LabelPrimitive.Root>
    );
  },
);

Label.displayName = "Label";
